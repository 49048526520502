import axios from '../api/axios';
import { FraudReport } from '../models/FraudReport';
import { WebsiteReport } from '../models/WebsiteReport';


const downloadFraudReportEvaluation = async (fraudReport: FraudReport): Promise<string> => {
    try {
        const response = await axios.post(
            '/generate/evaluation/fraud-report',
            fraudReport,
            { responseType: 'blob' } // Importante per gestire la risposta binaria
        );

        // Crea un link per scaricare il file
        return window.URL.createObjectURL(new Blob([response.data]));
    } catch (error) {
        throw new Error('Errore durante il download dell\'esposto');
    }
};


const downloadWebsiteReportEvaluation = async (websiteReport: WebsiteReport): Promise<string> => {
    try {
        console.log("websiteReport");
        console.log(websiteReport);
        const response = await axios.post(
            '/generate/evaluation/website-report',
            websiteReport,
            { responseType: 'blob' } // Importante per gestire la risposta binaria
        );

        // Crea un link per scaricare il file
        return window.URL.createObjectURL(new Blob([response.data]));
    } catch (error) {
        throw new Error('Errore durante il download dell\'analisi del sito web');
    }
};

export { downloadFraudReportEvaluation, downloadWebsiteReportEvaluation };
