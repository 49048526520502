import axios from '../api/axios';
import { isAxiosError } from 'axios';
import { WebsiteReport } from "../models/WebsiteReport";

const fetchWebsiteReport = async (baseUrl: string): Promise<WebsiteReport> => {
    try {
        const response = await axios.post<WebsiteReport>('/analyze/website', { base_url: baseUrl });
        return response.data;
    } catch (error: any) {
        if (isAxiosError(error)) {
            // Gestione di errori specifici di Axios
            if (error.response) {
                // Se il server ha risposto con un codice di stato diverso da 2xx
                const statusCode = error.response.status;
                const errorDetail = error.response.data?.detail;

                // Gestione di errori specifici basati sul codice di stato o sul codice di errore personalizzato
                if (statusCode === 503 && errorDetail?.code === "WEBSITE_NOT_ACCESSIBLE") {
                    throw new Error("WEBSITE_NOT_ACCESSIBLE");
                } else if (statusCode === 503 && errorDetail?.code === "CONTENT_PROTECTED") {
                    throw new Error("CONTENT_PROTECTED");
                } else {
                    // Altri errori specifici dal backend
                    throw new Error(`Errore dal server: ${errorDetail?.message || "Errore sconosciuto"}`);
                }
            } else if (error.request) {
                // Se la richiesta è stata fatta ma non è stata ricevuta alcuna risposta
                throw new Error("Nessuna risposta dal server. Verifica la tua connessione e riprova.");
            } else {
                // Se è avvenuto un errore durante la configurazione della richiesta
                throw new Error(`Errore di richiesta: ${error.message}`);
            }
        } else {
            // Gestione di errori generali che non sono di Axios
            throw new Error(`Errore generico: ${error.message}`);
        }
    }
};

export { fetchWebsiteReport };

