import React, { useState } from 'react';
import FileUploader from '../components/FileUploader';
import { FraudReport } from '../models/FraudReport';
import FraudReportWidget from '../components/FraudReportWidget';
import Select from 'react-select';
import { WebsiteReport } from '../models/WebsiteReport';
import WebsiteReportWidget from '../components/WebsitreReportWidget';
import { analyzeFraudReport } from '../api/fraudReportApi';
import { fetchWebsiteReport } from '../api/websiteReportApi';
import { generatedReport, generateDelibera } from '../api/generateReport';
import { formatDateToItalian } from '../utils/datePriceUtils';
import { FaTrash, FaFileAlt, FaChevronLeft } from 'react-icons/fa';
import { ACCOUNT_OPENING_REPLACEMENT } from '../constants/textReplacements';



const CompleteAnalysis: React.FC = () => {
    const [blockedSitesFiles, setBlockedSitesFiles] = useState<File[]>([]);
    const [multipleFiles, setMultipleFiles] = useState<File[]>([]);
    const [analysisResult, setAnalysisResult] = useState<FraudReport | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedWebsites, setSelectedWebsites] = useState<string[]>([]);
    const [websiteReports, setWebsiteReports] = useState<{ [key: string]: WebsiteReport }>({});
    const [loadingWebsites, setLoadingWebsites] = useState<string[]>([]);
    const [isAnalyzingWebsites, setIsAnalyzingWebsites] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [websiteNotFound, setWebsiteNotFound] = useState<string[]>([]);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };



    // Definisci la funzione che prende in input due stringhe e le concatena
    async function generateReportForWebsite(fraudReport: FraudReport, websiteReport: WebsiteReport): Promise<string> {
        // Concatena le due stringhe
        const replacements: { [key: string]: string[] } = {}
        const today = new Date();
        replacements['URL'] = [websiteReport.base_url];
        replacements['MEZZO_CONTATTO'] = fraudReport.initial_contact_methods;
        replacements['STRUMENTI_FINANZIARI'] = websiteReport.financial_instruments;
        replacements['SOCIETA_ENTITA'] = websiteReport.contractual_counterpart;
        replacements['DATA'] = [formatDateToItalian(today)];
        replacements['ALLOWS_REGISTRATION'] = [websiteReport.allows_user_registration ? ACCOUNT_OPENING_REPLACEMENT : ""];
        return generatedReport(replacements)
    };

    async function generateDeliberaForWebsite(fraudReport: FraudReport, websiteReport: WebsiteReport): Promise<string> {
        // Concatena le due stringhe
        const replacements: { [key: string]: string[] } = {}
        const today = new Date();
        replacements['URL'] = [websiteReport.base_url];
        replacements['LOGIN_URL'] = websiteReport.login_registration_urls;
        replacements['REGISTRATION_URL'] = websiteReport.login_registration_urls;
        replacements['MEZZO_CONTATTO'] = fraudReport.initial_contact_methods;
        replacements['STRUMENTI_FINANZIARI'] = websiteReport.financial_instruments;
        replacements['SOCIETA_ENTITA'] = websiteReport.contractual_counterpart;
        replacements['DATA'] = [formatDateToItalian(today)];
        replacements['ATTIVITA_MIFID'] = websiteReport.mifid_activities;
        replacements['EMAIL'] = websiteReport.emails;
        return generateDelibera(replacements)
    };


    const [isDownloadReportLoading, setisDownloadReportLoading] = useState(false);
    const [isDownloadingDeliberaLoading, setisDownloadingDeliberaLoading] = useState(false);

    const handleDownloadRelation = async (fraudReport: FraudReport, websoteReport: WebsiteReport) => {
        setisDownloadReportLoading(true);
        try {
            const url = await generateReportForWebsite(fraudReport, websoteReport)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_${websoteReport.service_name ?? ''}.docx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the screenshots', error);
        } finally {
            setisDownloadReportLoading(false);
        }
    };

    const handleDownloadDelibera = async (fraudReport: FraudReport, websoteReport: WebsiteReport) => {
        setisDownloadingDeliberaLoading(true);
        try {
            const url = await generateDeliberaForWebsite(fraudReport, websoteReport)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `delibera_${websoteReport.service_name ?? ''}.docx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the screenshots', error);
        } finally {
            setisDownloadingDeliberaLoading(false);
        }
    };





    const handleBlockedSitesUpload = (acceptedFiles: File[]) => {
        setBlockedSitesFiles([...blockedSitesFiles, ...acceptedFiles]);
    };

    const handleMultipleFilesUpload = (acceptedFiles: File[]) => {
        setMultipleFiles([...multipleFiles, ...acceptedFiles]);
    };

    const handleRemoveBlockedSiteFile = (fileToRemove: File) => {
        setBlockedSitesFiles(blockedSitesFiles.filter(file => file !== fileToRemove));
    };

    const handleRemoveMultipleFile = (fileToRemove: File) => {
        setMultipleFiles(multipleFiles.filter(file => file !== fileToRemove));
    };

    const handleSubmit = async () => {

        setWebsiteNotFound([])
        setWebsiteReports({})
        setAnalysisResult(null)

        if (multipleFiles.length === 0) {
            alert('Devi caricare almeno un file esposto.');
            return;
        }

        setIsSubmitting(true);

        try {
            const formData = new FormData();
            if (blockedSitesFiles.length > 0) {
                formData.append('url_file', blockedSitesFiles[0]);
            }
            multipleFiles.forEach((file, index) => {
                formData.append(`uploaded_files`, file, file.name);
            });

            console.log(formData);

            const response = await analyzeFraudReport(blockedSitesFiles, multipleFiles)

            setAnalysisResult(response);
        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSelectChange = (selectedOptions: any) => {
        setSelectedWebsites(selectedOptions.map((option: any) => option.value));
    };

    const analyzeSelectedWebsites = async () => {
        for (const website of selectedWebsites) {
            if (!loadingWebsites.includes(website)) {
                setIsAnalyzingWebsites(true)
                setLoadingWebsites((prev) => [...prev, website]);

                try {
                    const response = await fetchWebsiteReport(website);
                    setWebsiteReports((prev) => ({ ...prev, [website]: response }));
                } catch (error) {
                    setWebsiteNotFound((prev) => [...prev, website])
                    console.error(`Error analyzing ${website}:`, error);
                } finally {
                    setLoadingWebsites((prev) => prev.filter((url) => url !== website));
                    setIsAnalyzingWebsites(false)
                }
            }
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4 text-primary-color">Analisi Esposti</h1>

            {/* Primo contenitore per il FileUploader */}
            <div className="border border-gray-300 rounded-lg shadow-sm my-6 w-full max-w-4xl">
                <div
                    className="flex justify-between items-center px-4 py-3 cursor-pointer hover:bg-gray-50"
                    onClick={toggleAccordion}
                >
                    <h2 className="font-medium">Carica il file contenente i siti oscurati</h2>
                    {/* Icona che ruota */}
                    <FaChevronLeft
                        className={`transform transition-transform duration-300 ${isAccordionOpen ? '-rotate-90' : 'rotate-0'}`} // Classe di rotazione
                    />
                </div>

                {/* Aggiungi la transizione per l'apertura e chiusura dell'accordion */}
                <div
                    className={`overflow-hidden transition-all duration-500 ease-in-out ${isAccordionOpen ? 'max-h-96' : 'max-h-0'
                        }`}
                >
                    <div className="p-6">
                        <FileUploader
                            onDrop={handleBlockedSitesUpload}
                            label="Trascina e rilascia il file qui, o fai clic per selezionare i file"
                            maxFiles={1}
                            acceptedFormats={['txt']}
                        />
                        <div>
                            {blockedSitesFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between items-center py-2 px-6 w-full border rounded-md mb-2 shadow-sm"
                                >
                                    <div className="flex items-center">
                                        <FaFileAlt className="text-gray-700 mr-2 text-2xl" />
                                        <div className="flex flex-col px-4 text-left">
                                            <span className="text-gray-700 font-medium">{file.name}</span>
                                            <span className="text-gray-500 text-sm">
                                                ({(file.size / 1024).toFixed(2)} KB)
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => handleRemoveBlockedSiteFile(file)}
                                        className="text-red-700 hover:text-red-900"
                                    >
                                        <FaTrash />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Secondo contenitore per il FileUploader */}
            <div className={`border border-gray-300  rounded-lg shadow-sm my-6 w-full max-w-4xl`}>
                <div className={`flex justify-between items-center bg-gray-50 px-4 py-3 cursor-pointer border-b`}>
                    <h2 className="font-medium">Carica i documeti da processare</h2>
                </div>
                <div className='p-6'>
                    <FileUploader onDrop={handleMultipleFilesUpload} label="Trascina e rilascia più file qui, o fai clic per selezionare i file" />
                    <div>
                        {multipleFiles.map((file, index) => (
                            <div key={index} className="flex justify-between items-center py-2 px-6 w-full border rounded-md mb-2 shadow-sm">
                                <div className="flex items-center">
                                    <FaFileAlt className="text-gray-700 mr-2 text-2xl" />
                                    <div className='flex flex-col px-4 text-left'>
                                        <span className="text-gray-700 font-medium">{file.name}</span>
                                        <span className="text-gray-500 text-sm">({(file.size / 1024).toFixed(2)} KB)</span>
                                    </div>
                                </div>
                                <button onClick={() => handleRemoveMultipleFile(file)} className="text-red-700 hover:text-red-900">
                                    <FaTrash />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='max-w-4xl'>
                <h2 className='font-semibold text-primary-color mb-2'>Istruzioni per l'Uso</h2>
                <ol className='list-decimal ml-5'>
                    <li>Nella sezione <span className='font-semibold'>"Carica il file contenente i siti oscurati"</span>, carica il file che contiene l'elenco dei domini oscurati. Il file deve essere in formato .txt e ogni riga deve rappresentare un dominio oscurato. <span className='font-semibold'>(facoltativo)</span></li>
                    <li>Nella sezione <span className='font-semibold'>"Carica i documenti da processare"</span>, carica i file relativi all'esposto. I file possono essere in formato .pdf, .jpg, .jpeg, o .png. <span className='font-semibold'>(obbligatorio)</span></li>
                    <li>Seleziona <span className='font-semibold'>"Procedi all'analisi"</span> per avviare il processo di analisi dei documenti. <span className='font-semibold'>(obbligatorio)</span></li>
                    <li>Modifica l'esito dell'analisi se ritieni che i risultati non siano in linea con la valutazione dell'operatore. <span className='font-semibold'>(facoltativo)</span></li>
                    <li>Seleziona i siti web da processare.  <span className='font-semibold'>(obbligatorio)</span></li>
                    <li>Scarica gli screenshot del sito. <span className='font-semibold'>(facoltativo)</span></li>
                    <li>Scarica il modello di relazione per ciascun sito. <span className='font-semibold'>(facoltativo)</span></li>
                </ol>
            </div>
            <div className="mt-6">
                <button
                    onClick={handleSubmit}
                    className={`bg-primary-color text-white px-4 py-2 rounded-md flex items-center justify-center ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <span>Analisi in corso</span>
                            <svg className="animate-spin h-5 w-5 ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </>
                    ) : 'Procedi all\'analisi'}
                </button>
            </div>


            <div className='max-w-4xl'>
                {analysisResult && (
                    <>
                        <FraudReportWidget report={analysisResult} setReport={setAnalysisResult} />
                        <div className="container mx-auto border rounded-lg p-6 shadow-md my-6">
                            <div>
                                <h1 className="text-lg font-medium text-primary-color ">Seleziona Siti Web da analizzare</h1>
                                <p className='py-4'>
                                    Seleziona i siti web rilevati nell'esposto per procedere con l'analisi. Se necessario, puoi aggiungere manualmente altri siti web che desideri includere nella valutazione.
                                </p>
                            </div>
                            <Select
                                isMulti
                                options={analysisResult.websites.map((site: string) => ({ value: site, label: site }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleSelectChange}
                                value={selectedWebsites.map(site => ({ value: site, label: site }))}
                            />
                            <div className='my-4'>
                                <button
                                    onClick={analyzeSelectedWebsites}
                                    className={`bg-primary-color text-white px-4 py-2 rounded flex items-center justify-center ${isAnalyzingWebsites ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={isAnalyzingWebsites}
                                >
                                    {isAnalyzingWebsites ? (
                                        <>
                                            <span>Analisi Siti Web in corso</span>
                                            <svg className="animate-spin h-5 w-5 ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                            </svg>
                                        </>
                                    ) : 'Analizza i Siti Web Selezionati'}
                                </button>
                            </div>
                        </div>
                    </>
                )}
                {websiteNotFound.map(url => (
                    <>
                        <div className="container mx-auto border rounded-lg p-6 shadow-md my-6">
                            <h2 className="text-lg font-medium"> Il sito <span className='text-red-700'>{url}</span> non è attualmente raggiungibile.</h2>
                            <p className="mt-4 ">Il sito web <span className='text-red-700'>{url}</span> risulta non accessibile. Verifica che l'indirizzo inserito sia corretto e controlla eventuali problemi di connessione o errori del server. Prova a ricaricare la pagina o riprova più tardi. </p>
                        </div>

                    </>))

                }
                {Object.keys(websiteReports).map((url, index) => (
                    <>
                        <WebsiteReportWidget report={websiteReports[url]} setReport={(re) => {
                            if (re) { // Controlla che re non sia null
                                setWebsiteReports(prevReports => ({
                                    ...prevReports,
                                    [url]: re, // Assegna solo se 're' non è null
                                }));
                            }


                        }} />
                        <div className="container mx-auto border rounded-lg p-6 shadow-md my-6">
                            <h2 className="text-lg font-medium">Relazione precompilata: {websiteReports[url].base_url}</h2>
                            <p className="mt-4 text-gray-500">La relazione verrà generata automaticamente utilizzando un modello predefinito, integrando i dati estratti dall'esposto e dal sito web.</p>
                            <button
                                onClick={() => handleDownloadRelation(analysisResult!, websiteReports[url])}
                                className="bg-primary-color text-white px-4 py-2 my-4 rounded-md"
                                disabled={isDownloadReportLoading}
                            >
                                {isDownloadReportLoading ? 'Elaboro Relazione...' : 'Scarica Relazione'}
                            </button>
                        </div>
                        <div className="container mx-auto border rounded-lg p-6 shadow-md my-6">
                            <h2 className="text-lg font-medium">Delibera precompilata: {websiteReports[url].base_url}</h2>
                            <p className="mt-4 text-gray-500">La delibera verrà generata automaticamente utilizzando un modello predefinito, integrando i dati estratti dall'esposto e dal sito web.</p>
                            <button
                                onClick={() => handleDownloadDelibera(analysisResult!, websiteReports[url])}
                                className="bg-primary-color text-white px-4 py-2 my-4 rounded-md"
                                disabled={isDownloadingDeliberaLoading}
                            >
                                {isDownloadingDeliberaLoading ? 'Elaboro Delibera...' : 'Scarica Delibera'}
                            </button>
                        </div>

                    </>

                ))}
            </div>
        </div>
    );
};

export default CompleteAnalysis;
